import API from "@/helpers/Api";

export default class PrecisionRepository extends API {


    orderByOptions= [
        { text: 'id', value: 'id', name: 'pr.id' },
        { text: 'libelle', value: 'libelle', name: 'pr.libelle' },
        { text: 'tarif', value: 'tarif', name: 'pr.tarif' },
        {text: 'createdAt', value:"createdAt", name: 'pr.createdAt'},
        {text: 'updatedAt', value:'updatedAt', name: 'pr.updatedAt'},
        {text: 'enabled', value:'enabled', name: 'pr.enabled'}
    ];

    async create(data){
        const precisions = await this.post('/v2/precisions', data)
        return precisions;
    }
    edit(data, id){
        const precisions = this.patch('/v2/precisions/'+id, data)
        return precisions;
    }
    find(id){
        const precisions = this.findOne('/v2/precisions/'+id)
        return precisions;
    }
    supprimer(id){
        const precisions = this.delete('/v2/precisions/'+id)
        return precisions;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {

        const precisions = this.listing('/v2/precisions', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(precisions);
        return precisions;
    }


}