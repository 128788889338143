import ParMoyen_paiement from "@/components/statistiques/Recouvrement/ParMoyen_paiement.vue";
import ParSous_EntiteRec from "@/components/statistiques/Recouvrement/ParSous_EntiteRec.vue";
import recouvrementParAcitive from "@/components/statistiques/Recouvrement/recouvrementParAcitive.vue";
import RecouvrementParAgent from "@/components/statistiques/Recouvrement/RecouvrementParAgent.vue";
export default [
    {
        path:'/statistiques/recouvrement/par_moyen_paiement',
        name: 'recouvrementParMoyenPaiement',
        component: ParMoyen_paiement
    },
    {
        path:'/statistiques/recouvrement/par_sous_entite',
        name: 'recouvrementParSousEntite',
        component: ParSous_EntiteRec
    },
    {
        path:'/statistiques/recouvrement/par_activite',
        name: 'recouvrementParActivite',
        component: recouvrementParAcitive
    },
    {
        path:'/statistiques/recouvrement/par_agent',
        name: 'recouvrementParAgent',
        component: RecouvrementParAgent
    },
]