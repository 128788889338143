import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class TypeEquipementRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'teq.id' },
        { text: 'nom', value: 'nom', name: 'teq.nom' },
        { text: 'code', value: 'code', name: 'teq.code' },
        { text: 'description', value: 'description', name: 'teq.description' },
        { text: 'date de creation', value: 'createdAt', name: 'teq.createdAt' },
        { text: 'active', value: 'enabled', name: 'teq.enabled' },
    ];

    create(data){
        const type_equipements = this.post('/v2/type_equipements', data)
        return type_equipements;
    }
    edit(data, id){
        const type_equipements = this.patch('/v2/type_equipements/'+id, data)
        return type_equipements;
    }
    find(id){
        const type_equipements = this.findOne('/v2/type_equipements/'+id)
        return type_equipements;
    }
    supprimer(id){
        const type_equipements = this.delete('/v2/type_equipements/'+id)
        return type_equipements;
    }
    async search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const type_equipements = await this.listing('/v2/type_equipements', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return type_equipements;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const type_equipements = await this.listing('/v2/type_equipements?orderBy=teq.nom&sortOrder=asc&itemperpage=30&page=1&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(type_equipements);
                return type_equipements;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const type_equipements = await this.listing('/v2/type_equipements?orderBy=teq.nom&sortOrder=asc&itemperpage=30&page=1&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(type_equipements);
                return type_equipements;
            }
        }
    }
    getReleve(equipement, entite, ramification, from, to){
        const equipements = this.poste('/v2/contribuables/releve-impaye?generate=true&entite='+entite+'&ramification='+ramification+'&equipement='+equipement+'&from='+from+'&to='+to)
        return equipements;
    }

}