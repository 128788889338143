import API from "@/helpers/Api";

export default class Taxe {

    id;
    taxe;
    categorie;
    nom;
    natureActivite;
    typeEquipement;
    frequence;
    applications;
    appartenance;
    entite;
    enabled
    createdAt;
    updatedAt;
    api = new API();

    constructor() {
        this.id = null;
        this.taxe = null;
        this.appartenance = null;
        this.natureActivite = null;
        this.frequence = null;
        this.applications = [];
        this.entite = null;
        this.typeEquipement = null;
        this.categorie = null;
        this.nom = null;
        this.enabled = false;

    }


  
     

}