<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Utilisateurs</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
            <li class="breadcrumb-item active">Utilisateurs</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header text-start">
          <h3 class="card-title mb-0">Permissions - {{ role.nom }}</h3>
        </div>
        <div class="card-body" v-if="isLoading">
          Loading........
        </div>
        <div class="card-body" v-else>
          <table v-for="(nav, index) in listNavigation" class="table table-primary table-striped-columns text-start" v-bind:key="nav.id">
            <tr>
              <td class="text-uppercase p-2"><strong>{{ nav.nom }}</strong>  </td>
              <td class="text-end"><input v-model="selectAll[nav.id]" id="selectAll" @click="handleSelectAll(nav, index)"  type="checkbox"></td>
            </tr>
            <tr v-for="permission in nav.permissions" v-bind:key="permission">
              <td>{{ permission }} </td>
              <td class="text-end"><input type="checkbox"   v-model="selectedPermissions.find(item => item.navigation === nav.id && item.permission === permission).selected" /> </td>
            </tr>
          </table>
        </div>

        <div class="card-footer">
          <button  class="btn btn-secondary waves-effect waves-light m-2">Annuler</button>
          <button @click="enregistrer"  class="btn btn-primary waves-effect waves-light m-2">Enregistrer</button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import GroupRepository from "@/repository/GroupRepository";
import NavigationRepository from "@/repository/NavigationRepository";
import PermissionRepository from "@/repository/PermissionRepository"
import Group from "@/entity/Group";
export default {
  data(){
    return {

      listNavigation: null,
      repository: new NavigationRepository(),
      groupRepo: new GroupRepository(),
      permRepo: new PermissionRepository(),
      role: new Group(),
      selectAll: [],
      permissions: [],
      isLoading: false,
      selectedNavigations: [],
      selectedPermissions: []

    }
  },
  methods: {

   async enregistrer(){
      this.permissions = this.selectedPermissions.filter(item => item.selected === true);
      if(this.selectedPermissions.length > 0){
        this.isLoading = true;
         this.permissions.forEach(permission => {
          this.permRepo.create(permission)
              .then()
              .catch(error =>{
                console.log(error);
              });

        });
        this.isLoading = false;


      }
      this.selectedPermissions = [];
      console.log(this.permissions)
    },
    handleSelectAll(nav) {
      this.selectedPermissions.forEach(selected => {
        if (selected.permission){
          if (selected.navigation === nav.id  ){
            if (selected.selected === false)
              nav.permissions.forEach(permission => {
                if (selected.permission === permission)
                  selected.selected = true;
              })
            else
              nav.permissions.forEach(permission => {
                if (selected.permission === permission)
                  selected.selected = false;
              })
          //  console.log(index);
          }

        }
      })

      //console.log(this.selectedPermissions);
    //  console.log(nav.id);
    }

  },
  name: 'RolePermissionsPage',

  created() {
    this.groupRepo.find(this.$route.params.id)
        .then(response => {
          this.role = response
          //console.log(this.role)
        })
        .catch(error => {
          console.log(error);
        })
    this.repository.search([], 'nav.id', 'asc', 30, 1)
        .then(response => {
          this.listNavigation = response.data
          this.listNavigation.forEach(item => {
           // this.selectAll.push({'navigation': item.id, 'selected': false});
            item.permissions.forEach(permission => {
              this.selectedPermissions.push({ role: this.role.id , navigation: item.id, permission: permission, 'selected': false })
            })
          });
        // console.log(this.selectAll)
        })
        .catch(error => {
          console.log(error);
        })


  }
}
</script>