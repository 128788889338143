import API from "@/helpers/Api";

export default class Ramification {

    id;
    nom;
    parent;
    entite;
    code;
    cptr;
    type;
    cptrContrib;
    cptrCarte;
    description;
    createdAt;
    updatedAt;
    enabled = false;
    api = new API();
    constructor() {
        this.id = null;
        this.nom = null;
        this.entite = null;
        this.type = null;
        this.code = null;
        this.enabled = false;
    }


  
     

}