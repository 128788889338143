import API from "@/helpers/Api";

export default class GroupRepository extends API {


    orderByOptions= [
        { text: 'id', value: 'id', name: 'g.id' },
        { text: 'nom', value: 'nom', name: 'g.nom' },
        { text: 'description', value: 'description', name: 'g.description' },
        { text: 'createdAt', value: 'createdAt', name: 'g.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'g.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'g.enabled' },
    ];

    create(data){
        const groups = this.post('/v2/groups', data)
        return groups;
    }
    edit(data, id){
        const groups = this.patch('/v2/groups/'+id, data)
        return groups;
    }
    find(id){
        const groups = this.findOne('/v2/groups/'+id)
        return groups;
    }
    supprimer(id){
        const groups = this.delete('/v2/groups/'+id)
        return groups;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {

        const groups = this.listing('/v2/groups', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(groups);
        return groups;
    }


}