import Dashboard from "@/components/Dashboard";


export default [
    {
        path: '/',
        name: 'home',
        component: Dashboard
    },
    {
        path: '/:id',
        name: 'subDashboardRamification',
        component: Dashboard
    },
]