import ListeNatureActivite from "@/components/nature_activite/ListeNatureActivite";
import CreateNatureActivite from "@/components/nature_activite/CreateNatureActivite";
import EditNatureActivite from "@/components/nature_activite/EditNatureActivite";


export default [

    {
        path: '/nature-activite',
        name: 'listNatureActivite',
        component: ListeNatureActivite
    },
    {
        path: '/nature-activite/create',
        name: 'createNatureActivite',
        component: CreateNatureActivite
    },
    {
        path: '/nature-activite/:id',
        name: 'editNatureActivite',
        component: EditNatureActivite
    }
]