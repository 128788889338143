<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Contribuable</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Collectivité</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Validation contribuables</router-link>
            </li>
            <li class="breadcrumb-item active">Fiche individuelle</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div v-if="contribuable != null">
    <div class="row">
      <div class="col-lg-12">
<!--        <button style="float: right" @click="exportToPDF" type="button" class="btn btn-success add-btn"
                data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"><i
            class="ri-file-pdf-fill align-bottom me-1"></i> PDF
        </button>-->
          <img src="../../assets/images/logobko.png" style="width: 100px;height: 100px;" alt="" class="profile-wid-img"/>
        <div  id="element-to-convert">
          <!-- Tab panes -->
          <div class="tab-content pt-4 text-muted">
            <div>
              <div class="row">
                <!--end col-->
                <h1>Fiche individuelle du contribuable - Année {{anneefiscale()}}</h1>
                <div class="col-xxl-12">
                  <div class="card">
                    <div class="card-body text-start">
                      <div class="card-header align-items-center d-flex">
                        <!--                        <h4 class="card-title mb-0  me-2">Information générale</h4>-->
                        <h6 class="card-title mb-0  me-2"
                            v-if="contribuable.ramification.parent != null">{{ contribuable.ramification.entite.nom }} >
                          {{ contribuable.ramification.parent.nom }}
                          > {{ contribuable.ramification.nom }} > {{ contribuable.numKlis }}</h6>
                      </div>
                      <br/><br/>
                      <div style="float: left; margin-right: 20px;">
                        <div class="col-auto">
                          <div class="avatar-lg">
                            <img style="width: 100px;height: 130px" :src="'https://api.klispay.com/uploads/'+contribuable.photo"
                                 :alt="contribuable.fullName"
                                 class="img-thumbnail rounded-circle"/>
                          </div>
                        </div>
                      </div>
                      <div style="float: left;margin-right: 20px;" class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr>
                            <th class="ps-0" scope="row">N° KLISPAY :</th>
                            <td class="text-muted">{{ contribuable.numKlis }}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Nom :</th>
                            <td class="text-muted">{{ contribuable.fullName }}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Telephone :</th>
                            <td class="text-muted">{{ contribuable.telephone }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div style="float: left" class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr>
                            <th class="ps-0" scope="row">Adresse :</th>
                            <td class="text-muted">{{ contribuable.adresse }}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Date d'enrolement :</th>
                            <td class="text-muted">{{ contribuable.createdAt.toString().slice(0, 10) }}
                            </td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Piece d'identité</th>
                            <td class="text-muted">{{ contribuable.numeroPieceIdentite }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                    <!-- end card body -->
                  </div>
                  <div class="row">
                    <div class="col-lg-12 text-start">
                      <div class="card">
                        <div class="card-header align-items-center d-flex">
                          <h6 class="card-title mb-0  me-2">Activité</h6>

                        </div>
                        <div class="card-body text-start">
                          <!--                          <div class="tab-content text-muted">

                                                      <h6 class="card-title flex-grow-1 mb-0">Taxes</h6>

                                                    </div>-->
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="table table-borderless align-middle mb-0">
                                <table class="table table-borderless align-middle mb-0">
                                  <thead class="table-light">
                                  <tr>
                                    <th>Base taxable</th>
                                    <th>Précision</th>
                                    <th>Nombre</th>
                                    <th>Prix unitaire</th>
                                    <th>Total</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="item in occurence()" :key="item">
                                    <td> {{item.taxe}} </td>
                                    <td> {{item.precision}}</td>
                                    <td>{{item.nbre}}</td>
                                    <td>{{formattedNumber(item.montant)}} </td>
                                    <td>{{formattedNumber((Number(item.montant) * Number(item.nbre)))}} </td>

                                  </tr>

                                  </tbody>
                                  <tfoot>
                                  <tr>
                                    <th class="text-center" colspan="2">TOTAL</th>
                                    <th>{{totalNbreBaseTaxable()}}</th>
                                    <th>{{formattedNumber(totalPrixUnitaireBaseTaxable())}}</th>
                                    <th>{{formattedNumber(totalBaseTaxable())}}</th>
                                  </tr>
                                  </tfoot>
                                </table>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-lg-12 text-start">
                      <div class="card">
                        <div class="card-header align-items-center d-flex">
                          <h6 class="card-title mb-0  me-2">Situation fiscale</h6>

                        </div>
                        <div class="card-body text-start">
                          <!--                          <div class="tab-content text-muted">
                                                      <h6 class="card-title flex-grow-1 mb-0">Transactions</h6>

                                                    </div>-->
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="table table-borderless align-middle mb-0">
                                <table class="table table-borderless align-middle mb-0">
                                  <thead class="table-light">
                                  <th>Date</th>
                                  <th>ID transaction</th>
                                  <th>Moyen de paiement</th>
                                  <th>Montant prévu</th>
                                  <th>Montant payé</th>
                                  <th>Reste à payer</th>
                                  <th>Agent</th>
                                  <th>Etat</th>
                                  </thead>
                                  <tbody>
                                  <tr v-for="trans in contribuable.transactions.filter(item => item.statut === 'Confirmé' && formatYear(item.dateTransaction) === anneefiscale())"
                                      :key="trans">
                                    <td>{{ printDate(trans.dateTransaction) }}</td>
                                    <td>{{ trans.id }}</td>
                                    <td>{{ trans.moyenPaiement.nom }}</td>
                                    <td>{{ formattedNumber( contribuable.situation.total) }}</td>
                                    <td>{{ formattedNumber(contribuable.situation.payer) }}</td>
                                    <td>{{ formattedNumber(contribuable.situation.du) }}</td>
                                    <td>{{ trans.bureauAccepteur.fullName }}</td>
                                    <td v-if="contribuable.situation.total == contribuable.situation.payer"><span class="badge bg-success">Soldé</span></td>
                                    <td v-if="contribuable.situation.total != contribuable.situation.payer"><span class="badge bg-success">Avance</span></td>
                                  </tr>
                                  </tbody>
                                </table>

                              </div>
                              <div v-if="contribuable.transactions.length == 0"
                                   class="text-center mt-3">
                                <a href="javascript:void(0);" class="text-success"><i
                                ></i> Pas de transaction </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- end row -->
                  <br/><br/>
                  <h6 style="float: left; margin-left: 15px">NB: Sauf erreur, votre situation fiscale à date est de ({{ convertoLetter(contribuable.situation.total) }} Franc CFA) {{ formattedNumber(contribuable.situation.total) }} F CFA</h6>
                  <br/><br/><br/>
                  <h6 style="float: right;margin-right: 15px">Chef de recouvrement</h6>
                </div>
                <!--end col-->
              </div>

              <!--end row-->
            </div>
            <!--end tab-pane-->

          </div>
          <!--end tab-content-->
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
</template>

<script>
import Repository from '@/repository/ContribuableRepository'
import moment from "moment";
import html2pdf from "html2pdf.js";
import NumberToWord from "@/helpers/NumberToWord";

export default {
  name: 'ficheIndividuelle',
  data() {
    return {
      converter: new NumberToWord(),
      repository: new Repository(),
      contribuable: null,
      center: {lat: 51.093048, lng: 6.842120},
      markers: [
        {
          position: {
            lat: 51.093048, lng: 6.842120
          },
        }
        , // Along list of clusters
      ]
    }
  },
  methods: {
    convertoLetter(montant){
      return this.converter.NumberToLetter(Number(montant))
    },
    totalNbreBaseTaxable(){
      let sum =0;
      this.occurence().forEach(item => {
        sum += Number(item.nbre)
      })
      return sum;
    },
    totalPrixUnitaireBaseTaxable(){
      let sum =0;
      this.occurence().forEach(item => {
        sum += Number(item.montant)
      })
      return sum;
    },
    totalBaseTaxable(){
      let sum =0;
      this.occurence().forEach(item => {
        sum += (Number(item.montant)*Number(item.nbre))
      })
      return sum;
    },
    occurence(){
      const uniques =  [...new Set(this.contribuable.echecs.map(data => (data.taxe)))];
      const bases = [];
     let i ;
      uniques.forEach(it => {
         i = 0;

        this.contribuable.echecs.forEach(item => {
          if (item.taxe === it){
            //uniques.find(rep => rep.taxe === item.taxe).montant = item.montant;
           i++;
           bases[uniques.indexOf(it)] = {taxe: item.taxe, nbre: i, montant: item.montant, precision: item.precision};

          }

        })


      })


      return bases;

    },
    anneefiscale(){
      return moment().format('YYYY')
    },
    formatYear(date){
      return moment(date).format('YYYY')
    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas: {scale: 2},
        pagebreak: {mode: ['avoid-all', 'css', 'legacy', '.page-break']},
        filename: "Fiche-individuelle.pdf",
        image: { type: 'png', quality: 0.98 },
        jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'}
      });
    },
    getTotalRestant(annee, echec) {
      return this.getTotalEcheance(annee) + this.getTotalPayer(echec);
    },
    getTotalEcheance(annee) {
      var sum = 0;

      this.contribuable.echecs.filter(echec => echec.annee === annee).map(item => item.montant).forEach(echeance => {
        sum = sum + Number(echeance)
      })
      return sum;
    },
    getTotalPayer(echec) {
      var sum = 0;
      this.contribuable.transactions.filter(item => this.formatDate(item.dateTransaction) === echec.annee && item.statut === 'Confirmé').map(trans => trans.montant).forEach(item => {
        sum = sum + Number(item)
      })
      return sum;
    },
    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    formatDate(date) {
      return moment(date).format('YYYY')
    },
    printDate(dte) {
      var dt = new Date(dte);
      return dt.getDay() + '-' + dt.getMonth() + '-' + dt.getFullYear();
    }

  },
  created() {
    this.repository.find(this.$route.params.id)
        .then(response => {
          this.contribuable = response;
          console.log(this.contribuable);
        })
        .catch(error => {
          console.log(error)
        })
  }
}
</script>