<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Nature d'activité</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Configurations</router-link></li>
            <li class="breadcrumb-item active">Nature d'activité</li>
          </ol>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form @submit.prevent="createCollectivite">
          <div class="card card-animate">

            <div class="card-body">
              <div class="row text-start">
                <div class="col-sm-12">
                  <label for="firstName" class="form-label">Nom</label>
                  <input v-model="natureActivite.nom" type="text" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
              </div>
              <div class="row text-start mt-3">
                <div class="col-sm-12">
                  <label for="firstName" class="form-label">Catégorie</label>
                  <select v-model="natureActivite.categorie" type="text" class="form-control" required>
                    <option :key="item.id" :value="item.id" v-for="item in listCategorieActivite">{{item.nom}}</option>
                  </select>
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
              </div>

              <div class="row text-start mt-3">
                <div class="col-sm-12">
                  <label for="firstName" class="form-label">Collectivité</label>
                  <select v-model="natureActivite.entite" type="text" class="form-control" required>
                    <option :key="item.id" :value="item.id" v-for="item in listCollectivite">{{item.nom}}</option>
                  </select>
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
              </div>
              <div class="row text-start mt-3">
                <div class="col-sm-12">
                  <label for="firstName" class="form-label">Description</label>
                  <textarea v-model="natureActivite.description" type="text" class="form-control" ></textarea>
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="form-check form-switch form-switch-success">
                    <input  v-model="natureActivite.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                    Active ?
                  </div>
                </div>
              </div>

            </div>
            <div class="card-footer">
              <div class="row">
                <button type="submit" class="btn btn-primary">Enregistrer</button>

              </div>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
import NatureActivite from "@/entity/NatureActivite";
import Repository from '@/repository/NatureActiviteRepository'
import EntiteRepository from '@/repository/EntiteRepository'
import CategorieActiviteRepository from '@/repository/CategorieActiviteRepository'

export default {
  name: 'createNatureActivitePage',
  data() {
    return {
      natureActivite: new NatureActivite(),
      repository: new Repository(),
      entiteRepo: new EntiteRepository(),
      listCollectivite: null,
      categorieRepo: new CategorieActiviteRepository(),
      listCategorieActivite: null


    }
  },
  computed: {
  },
  methods: {
    createCollectivite(){
      this.repository.create(this.natureActivite);
      this.$router.push({ name: 'listNatureActivite' })
    }

  },
  created() {
    this.categorieRepo.search([{ colonne: 'ca.enabled', operator: 'eq', value: 1 }], 'ca.nom', 'asc', 30, 1)
        .then(response => {
          this.listCategorieActivite = response.data;
          //   console.log(this.listCollectivite);
        })
        .catch(error => {
          console.log(error);
        });
    this.entiteRepo.search([{ colonne: '', operator: '', value: '' }], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.listCollectivite = response.data;
          //   console.log(this.listCollectivite);
        })
        .catch(error => {
          console.log(error);
        });
  }
}
</script>