import API from "@/helpers/Api";
import store from "@/store";

export default class EntiteRepository extends API {


    orderByOptions= [
        { text: 'id', value: 'id', name: 'ent.id' },
        { text: 'nom', value: 'nom', name: 'ent.nom' },
        { text: 'type', value: 'type', name: 'te.nom' },
        { text: 'ID Type', value: 'type', name: 'te.id' },
        { text: 'adresse', value: 'adresse', name: 'ent.adresse' },
        { text: 'commission', value: 'commission', name: 'ent.commission' },
        { text: 'ville', value: 'ville', name: 'v.nom' },
        { text: 'description', value: 'description', name: 'ent.description' },
        { text: 'createdAt', value: 'createdAt', name: 'ent.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'ent.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'ent.enabled' },
    ];

    create(data){
        const entites = this.post('/v2/entites', data)
        return entites;
    }
    edit(data, id){
        const entites = this.patch('/v2/entites/'+id, data)
        return entites;
    }
    find(id){
        const entites = this.findOne('/v2/entites/'+id)
        return entites;
    }
    supprimer(id){
        const entites = this.delete('/v2/entites/'+id)
        return entites;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const entites = this.listing('/v2/entites', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return entites;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const entites = this.listing('/v2/entites?orderBy=ent.nom&sortOrder=asc&itemperpage=30&page=1&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(categorietaxes);
                return entites;
            }
            if(this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const entites = this.listing('/v2/entites?orderBy=ent.nom&sortOrder=asc&itemperpage=30&page=1&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(categorietaxes);
                return entites;
            }
        }
    }


}