<template>

  <h3> recouvrement Par Activite</h3>

</template>
<script>
export default {
  data() {
    return {
      a: ''
    }

  }
}

</script>
