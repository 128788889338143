import ParSous_Entite from "@/components/statistiques/Recensement/ParSous_Entite.vue";
import ParAgent from "@/components/statistiques/Recensement/ParAgent.vue";
import ParActivite from "@/components/statistiques/Recensement/ParActivite.vue";
import recouvrement from "@/router/recouvrement";
export default [
    {
        path: '/statistiques/recensement/sous_entite',
        name: 'recensementSousEntite',
        component: ParSous_Entite
    },
    {
        path: '/statistiques/recensement/par_agent',
        name: 'recensementAgent',
        component: ParAgent
    },
    {
        path: '/statistiques/recensement/par_activite',
        name: 'recensementActivite',
        component: ParActivite
    },
        ...recouvrement
]