<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
        </h4>


      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <Flatpickr
            @change="onDateChange"
            v-model="daterange"
            :config="config"
            class="form-control "
            placeholder="Select date"/>
        <div class="input-group-text bg-primary border-primary text-white">
          <i class="ri-calendar-2-line"></i>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <button @click="exportToPDF" class="btn btn-icon btn-success"><i class="ri-file-pdf-fill"></i> </button>
  </div>
  <div id="element-to-convert" >
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card pricing-box">
          <div class="card-body bg-light m-2 p-4">
            <h2 class="text-uppercase">SITUATION DES RECOUVREMENTS</h2>
            <table class="table table-bordered text-start">
              <thead>
              <tr>
                <th>Application: </th>
                <th>Klis'Pay</th>
              </tr>
              <tr>
                <th>Collectivité</th>
                <th>Quartier</th>
                <th>Zone</th>
                <th>Secteur</th>
              </tr>
              <tr>
                <th> <span v-if="entite != null"> {{entite.text}}</span> </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>Debut de période</th>
                <th>{{from}}</th>
              </tr>
              <tr>
                <th>Fin de période</th>
                <th>{{to}}</th>
              </tr>
              <tr>
                <th>Total payé</th>
                <th>{{formattedNumber(getTotalPayer())}}</th>
              </tr>

              <tr>
                <th>Type de rapport</th>
                <th>Relevé des recouvrements</th>
              </tr>
              <tr>
                <th>Générer le</th>
                <th>{{new Date().getDay() + '-' + new Date().getMonth() + '-' + new Date().getFullYear()}}</th>
              </tr>
              <tr>
                <th>Générer par</th>
                <th>{{user.fullName}}</th>
              </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <table class="table text-start mb-0 table-bordered table-responsive">
        <thead class="table-light">
        <tr class="text-center" >
          <th class="bg-success" scope="col" >#</th>
          <th class="bg-success" scope="col">Date</th>
          <th class="bg-success" scope="col">Heure</th>
          <th class="bg-success" scope="col" colspan="3">Contribuable</th>
          <th class="bg-success" scope="col" colspan="2">Paiement</th>
          <th class="bg-success" scope="col">Agent</th>
          <th class="bg-success" colspan="3" scope="col">Situations</th>
        </tr>
        <tr>
          <th style="width: 20px"></th>
          <th style="width: 80px"></th>
          <th style="width: 20px"></th>
          <th style="width: 200px">Nom/Raison sociale</th>
          <th  style="width: 100px">N° KlisPay</th>
          <th  style="width: 100px">Emplacement</th>
          <th style="width: 100px">Moyen de paiement</th>
          <th style="width: 100px">Montant</th>
          <th style="width: 100px">Nom</th>
          <th style="width: 100px">Prevu</th>
          <th style="width: 100px">Payer</th>
          <th style="width: 100px">Restant</th>

        </tr>
        </thead>
        <tbody >
        <tr v-for="(item, index) in listTransactions" :key="item.id">
          <td>{{ index+1 }}</td>
          <td>{{ item.dateTransaction.toString().slice(0,10) }}</td>
          <td>{{ item.dateTransaction.toString().slice(11,16) }}</td>
          <td>
            {{item.contrib.fullName}}
          </td>
          <td>{{item.contrib.numKlis}}</td>
          <td>{{item.contrib.ramification.nom}}</td>
          <td>{{item.moyenPaiement.nom}}</td>
          <td>{{formattedNumber(Number(item.montant))}}</td>
          <td >{{item.bureauAccepteur.fullName}}</td>
          <td>{{formattedNumber(getTotalEcheance(item.contrib.echecs))}}</td>
          <td>{{formattedNumber(Number(item.montant))}}</td>
          <td>{{formattedNumber((getTotalEcheance(item.contrib.echecs)- Number(item.montant)))}}</td>
        </tr>

        </tbody>
        <tfoot>
        <tr>
          <th colspan="9"></th>
          <th>{{formattedNumber(getSubTotalEcheance())}}</th>
          <th>{{formattedNumber(getTotalPayer())}}</th>
          <th>{{formattedNumber(Number(getTotalRestant()))}}</th>
        </tr>
        </tfoot>
      </table>


    </div>

  </div>

</template>

<script>
import html2pdf from "html2pdf.js"
import Loading from 'vue-loading-overlay';

import Select2 from 'vue3-select2-component';
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import fr from "flatpickr/dist/l10n/fr";
import RamificationRepository from "@/repository/RamificationRepository";
import DashboardRepository from "@/repository/DashboardRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import Repository from '@/repository/TransactionRepository'
import moment from "moment";

export default {
  name: 'ImpressionListeContribuablePage',
  computed: {
  },
  data(){
    return{
      isLoading: false,
      fullPage: true,
      listTransactions: null,
      repository: new Repository(),
      entite: null,
      departement: null,
      quartier: null,
      zone:null,
      secteur: null,
      ms: [],
      user: null,
      from: null,
      to: null,
      selectedEntite: null,
      daterange: null,
      selectedDepartement: null,
      selectedQuartier: null,
      selectedZone: null,
      selectedSecteur: null,
      fields: [
        { colonne: '', operator: '', value: '' }
      ],
      ramificationRepo: new RamificationRepository(),
      dashboardRepo: new DashboardRepository(),
      entiteRepo: new EntiteRepository(),
      myValue: '',
      listCollectivites: [],
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      config: {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        // defaultDate:[new Date(), new Date().setDate(new Date().getDate() + 7)]
      }
    }
  },
  methods:{
    getTotalPayer(){
      var sum = 0;
      if (this.listTransactions != null)
        this.listTransactions.forEach(item => {
          sum = sum +Number(item.montant);
        })
      return sum;
    },
    getSubTotalEcheance(){
      var sum = 0;
      if (this.listTransactions != null)
        this.listTransactions.forEach(item => {
          item.contrib.echecs.forEach(ech => {
            sum = sum + Number(ech.montant);
          })
        })
      return sum;
    },
    getTotalRestant()
    {
      return this.getSubTotalEcheance() - this.getTotalPayer()
    },
    getTotalEcheance(echecs){
      var sum = 0;
      console .log()
     // console.log( echecs.filter(item => item.annee === moment().format('YYYY').toString()))
      echecs.filter(item => item.annee === moment().format('YYYY').toString()).forEach(ech =>{
        sum = sum + Number(ech.montant);
      })

      return sum;

    },
    onDateChange(){
     if (this.entite!=null)
       this.fillTable()
    },
    exportToPDF() {
    //  let body = document.body
    //  let html = document.documentElement
      //let height = Math.max(body.scrollHeight, body.offsetHeight,
        //  html.clientHeight, html.scrollHeight, html.offsetHeight)
     // let element = document.querySelector('#contentForm')
      //let heightCM = height / 35.35
      html2pdf(document.getElementById("element-to-convert"), {
        filename: "liste-des-transactions.pdf",
        margin:       [10, 10, 15, 15], //top, left, buttom, right
        jsPDF:   {
          orientation: 'landscape',
          unit: 'pt',
          format: 'a4'
        }
      });
    },
    async fillTable(){
      try {
        this.isLoading=true;
        this.fields = [];
        this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
        this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];
        this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', value:this.from +' et '+ this.to})
        this.fields.push({colonne: 'trans.statut', operator: 'eq', value:'Confirmé'})


        //console.log(this.$route.query.departement);
        if (this.entite != null && this.departement === null) {
          this.fields.push({colonne: 'ent.id', operator: 'eq', value: this.entite.id});

        }
        if (this.departement != null && this.quartier === null){
          this.ms = this.listQuartiers.map(item => item.id);
          this.ms.push(this.departement.id)
          this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});

        }
        if (this.quartier != null && this.zone === null){
          this.ms = this.listZones.map(item => item.id);
          this.ms.push(this.quartier.id)
          this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});


        }
        if (this.zone != null && this.secteur === null){
          this.ms = this.listSecteurs.map(item => item.id);
          this.ms.push(this.zone.id)
          this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});
          //this.fields = [{colonne: 'ram.id', operator: 'in', value:  this.listSecteurs.map(item => item.id)}];

        }
        if (this.secteur != null ){
          this.fields.push({colonne: 'ram.id', operator: 'eq', value:  this.secteur});

        }


        const trResponse = await this.repository.search(this.fields, 'trans.dateTransaction', 'asc', 500000, 1);
        //console.log(trResponse)
        this.listTransactions = trResponse.data;
        //console.log(this.listTransactions);
        this.totalPages = Math.ceil(trResponse.meta.total_records / this.itemsPerPage);
        this.isLoading = false;

      }catch (e) {
        console.log(e);
      }
    },

    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();
            })
          });
    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();

          })
    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      this.isLoading=true;
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })


    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })

    },
  },
  created() {
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");
    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.entiteRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom})

          })
        })
  },
  components:{
    Select2,
    Flatpickr,
    Loading
  }
}
</script>