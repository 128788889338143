import ListeCategorieActivite from "@/components/categorie_activite/ListeCategorieActivite";
import CreateCategorieActivite from "@/components/categorie_activite/CreateCategorieActivite";
import EditCategorieActivite from "@/components/categorie_activite/EditCategorieActivite";


export default [

    {
        path: '/categorie-activite',
        name: 'listeCategorieActivite',
        component: ListeCategorieActivite
    },
    {
        path: '/categorie-activite/create',
        name: 'createCategorieActivite',
        component: CreateCategorieActivite
    },
    {
        path: '/categorie-activite/:id',
        name: 'editCategorieActivite',
        component: EditCategorieActivite
    }
]