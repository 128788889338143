<template>
  <loading v-model:active="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">{{ entite.nom }} > Créer un utilisateur</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
            <li class="breadcrumb-item active">Collectivité</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form ref="formulaire" @submit.prevent="createCategorie">
          <div class="card card-animate">

            <div class="card-body">
              <div class="row text-start">
                <div class="col-sm-6">
                  <label for="firstName" class="form-label">Nom</label>
                  <input v-model="user.nom" type="text" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
                <div class="col-sm-6">
                  <label for="firstName" class="form-label">Prenom</label>
                  <input v-model="user.prenom" type="text" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer le prenom de la l'utilisateur</div>
                </div>
              </div>

              <div class="row text-start mt-2">
                <div class="col-sm-6">
                  <label for="firstName" class="form-label">Nom d'utilisateur</label>
                  <input v-model="user.username" type="text" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer le nom de l'utilisateur</div>
                </div>
                <div class="col-sm-6">
                  <label for="firstName" class="form-label">Email</label>
                  <input v-model="user.email" type="text" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer une adresse email valide</div>
                </div>
              </div>

              <div class="row text-start mt-3">
                <div class="col-sm-6">
                  <label for="firstName" class="form-label">Mot de passe</label>
                  <input v-model="user.password" type="password" class="form-control" required>

                  <div class="invalid-feedback">Veuillez entrer le mot de passe</div>
                </div>
                <div class="col-sm-6">
                  <label for="firstName" class="form-label">Confirmer votre mot de passe</label>
                  <input v-model="user.passwordConfirm" type="password" class="form-control" required>
                  <div class="invalid-feedback">Veuillez repeter le mot de passe</div>
                </div>
              </div>
              <div class="row mt-3 text-start">
                <div class="col-md-12">
                  <label for="firstName" class="form-label">Groupe</label>

                  <select class="form-control" v-model="user.groupe" required>
                    <option :key="item" v-for="item in listGroupes" :value="item.id"> {{item.nom}}</option>
                  </select>
                  <div class="invalid-feedback">Veuillez choisir un groupe</div>

                </div>
              </div>
              <div class="row mt-3 text-start">
                <div class="col-md-12">
                  <label for="firstName" class="form-label">Collectivités</label>

                  <select @change="changeEntite" v-model="user.entites" class="form-control form-select"  required>
                    <option v-for="item in listEntites" :value="item.id" :key="item.id">{{item.nom}}</option>
                  </select>
                  <div class="invalid-feedback">Veuillez choisir une collectivité</div>

                </div>
              </div>
              <div class="row mt-3 text-start">
                <div class="col-md-12">
                  <label for="firstName" class="form-label">Ramifications</label>
                  <select v-model="user.ramifications" class="form-control "   multiple>
                    <option v-for="item in listRamifications" :value="item.id" :key="item.id">{{item.nom}}</option>
                  </select>

                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="form-check form-switch form-switch-success">
                    <input  v-model="entite.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                    Active ?
                  </div>
                </div>
              </div>

            </div>
            <div class="card-footer">
              <div class="row">
                <button type="submit" class="btn btn-primary">Enregistrer</button>

              </div>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Entite from "@/entity/Entite";
import EntiteRepository from '@/repository/EntiteRepository'
import VilleRepository from '@/repository/VilleRepository'
import User from "@/entity/User";
import UserRepository from "@/repository/UserRepository";
import GroupRepository from "@/repository/GroupRepository";
import RamificationRepository from "@/repository/RamificationRepository";
export default {
  name: 'createCategorieActivitePage',
  data() {
    return {
      isLoading : false,
      fullPage: true,
      entite: new Entite(),
      user: new User(),
      entiteRepository: new EntiteRepository(),
      groupRepository: new GroupRepository(),
      ramificationReop: new RamificationRepository(),
      repository: new UserRepository(),
      villeRepo: new VilleRepository(),
      listGroupes: null,
      listEntites: [],
      listRamifications: []


    }
  },
  computed: {
    Loading
  },
  methods: {
    createCategorie(){
      let entite = this.user.entites;
      this.user.entites = [];
      this.user.entites = [entite];
      const formulaire = this.$refs.formulaire;
      formulaire.classList.add('was-validated');
      if ((this.user.password === this.user.passwordConfirm) && this.user.nom != null && this.user.prenom != null && this.user.password != null && (this.user.entites != null || this.user.ramifications != null) && this.user.groupe != null ){
        formulaire.classList.remove('was-validated');

        if (this.user.ramifications === null){
          this.user.ramifications = [];
        }else{
          this.user.entites = [];
        }
        this.isLoading = true;
        this.repository.create(this.user)
            .then(response => {
              if (response.id){
                this.isLoading = false;
                this.$router.push({ name: 'listeEntite' })
              }
            })

      }




    },
    changeEntite()
    {

      this.ramificationReop.search([{colonne: 'ent.id', operator: 'in', value: this.user.entites}], 'ram.nom', 'asc', 100, 1)
          .then(response => {
            this.listRamifications = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    }

  },
  created() {

    this.entiteRepository.find(this.$route.params.id)
        .then(response => {
          this.entite = response;
        })
        .catch(error => {
          console.log(error);
        });
    this.groupRepository.search([{colonne: 'g.enabled', operator: 'eq', value: 1}], 'g.nom', 'asc', 100, 1)
        .then(response => {
          this.listGroupes = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    this.entiteRepository.search([{colonne: 'ent.id', operator: 'eq', value: this.$route.params.id} ], 'ent.nom', 'asc', 100, 1)
        .then(response => {
         this.listEntites = response.data;
        })
        .catch(error => {
          console.log(error);
        });
  },
  components:{
  }
}
</script>