



<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Modification enrôlement</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Collectivité</router-link></li>
            <li class="breadcrumb-item active">Enrôlement modification</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <!--<div class="row">
    <div class="col-md-12">
      <h5 style="float:left">Contribuables</h5>
    </div>
  </div>-->

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form @submit.prevent="rembourser"> <!--@submit.prevent="editTypeEquipement"-->
          <div class="card">
            <div class="card-body">
              <div class="col-sm-12 text-start" >
                <label for="Name" class="form-label">Montant</label>
                <input v-model="montant" type="text" class="form-control" />
                <div class="invalid-feedback">Veuillez entrer </div>
              </div>

            </div><!-- end card-body -->
            <div class="card-footer">
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </div><!-- end card -->


        </form>

      </div>
    </div>
  </div>
</template>

<script>
import TransactionRepository from "@/repository/TransactionRepository";
export default {
  name: 'EditEnrolementPage',

  data() {
    return {
      user: null,
      entite: null,
      transactionRepository: new TransactionRepository(),
      montant: null,
      fields: [
        { libelle: '', tarif: '', annee: '' }
      ],
      fieldsMontant: [
        { tarif: '', annee: '' }
      ],


    }
  },
  computed: {

  },
  methods: {

    remboursement() {

      var transaction ;
      transaction.contribuable = this.$router.params.id;
      transaction.montant = '-'+this.montant;
      transaction.moyenPaiement = 9;
      transaction.bureauAccepteur = this.user.id
      this.repository.create(transaction)
          .then(response => {
            if(response.id){
              this.$router.push({name: 'listeContribuable'})
            }
          });



    }
  },
  created() {
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;



  },
  components: {
  }
}
</script>


