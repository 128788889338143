import ListeCategorieTaxe from "@/components/categorie_taxe/ListeCategorieTaxe";
import CreateCategorieTaxe from "@/components/categorie_taxe/CreateCategorieTaxe";
import EditCategorieTaxe from "@/components/categorie_taxe/EditCategorieTaxe";


export default [

    {
        path: '/categorie-taxes',
        name: 'listCategorieTaxes',
        component: ListeCategorieTaxe
    },
    {
        path: '/categorie-taxes/create',
        name: 'createCategorieTaxes',
        component: CreateCategorieTaxe
    },
    {
        path: '/categorie-taxes/:id',
        name: 'editCategorieTaxes',
        component: EditCategorieTaxe
    }
]