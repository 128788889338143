<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Creation de taxe</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Configurations</router-link></li>
            <li class="breadcrumb-item active">Base taxable</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body form-steps">
          <form ref="formulaire" class="vertical-navs-step">
            <div class="row gy-5">
              <div class="col-lg-3">
                <div class="nav flex-column custom-nav nav-pills" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active" id="v-pills-bill-info-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bill-info" type="button" role="tab" aria-controls="v-pills-bill-info" aria-selected="true">
                                                        <span class="step-title me-2">
                                                            Etape 1
                                                        </span>
                    Information Générale
                  </button>
                  <button ref="step2" class="nav-link " id="v-pills-bill-address-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bill-address" type="button" role="tab" aria-controls="v-pills-bill-address" aria-selected="false">
                                                        <span class="step-title me-2">
                                                             Etape 2
                                                        </span>
                    Applications
                  </button>
                  <button ref="step3" class="nav-link" id="v-pills-payment-tab" data-bs-toggle="pill" data-bs-target="#v-pills-payment" type="button" role="tab" aria-controls="v-pills-payment" aria-selected="false">
                                                        <span class="step-title me-2">
                                                             Etape 3
                                                        </span>
                    Recap
                  </button>
                  <button ref="step4" class="nav-link" id="v-pills-finish-tab" data-bs-toggle="pill" data-bs-target="#v-pills-finish" type="button" role="tab" aria-controls="v-pills-finish" aria-selected="false">
                                                        <span class="step-title me-2">
                                                             Etape 4
                                                        </span>
                    Finalisation
                  </button>
                </div>
                <!-- end nav -->
              </div> <!-- end col-->
              <div class="col-lg-9">
                <div class="px-lg-4">
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="v-pills-bill-info" role="tabpanel" aria-labelledby="v-pills-bill-info-tab">


                      <div>
                        <div class="mb-2 row g-3 text-start">
                          <div class="col-sm-12">
                            <label for="firstName" class="form-label">Collectivité</label>
                            <select @change="initialisation" v-model="taxe.entite"  class="form-control form-select" required>
                              <option v-for="item in listCollectivite" :key="item.id" :value="item.id">{{ item.nom }}</option>
                            </select>
                            <div class="invalid-feedback">Veuillez choisir une collectivité</div>
                          </div>
                        </div>
                        <div class="mb-2 row g-3 text-start">
                          <div class="col-sm-12">
                            <label for="firstName" class="form-label">Année</label>
                            <select @change="setAnnee"  v-model="annee"  class="form-control form-select" multiple required>
                              <option v-for="item in listAnnee" :key="item" :value="item">{{ item }}</option>
                            </select>
                            <div class="invalid-feedback">Veuillez choisir l'année d'application de la taxe</div>
                          </div>
                        </div>
                        <div class="row g-3 text-start ">
                          <div class="col-sm-6">
                            <label for="firstName" class="form-label">Nom</label>
                            <input v-model="taxe.nom" type="text" class="form-control" required>
                            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                          </div>
                          <div class="col-sm-6">
                            <label for="firstName" class="form-label">Catégorie</label>
                            <select v-model="taxe.categorie"  class="form-control form-select" required>
                              <option v-for="item in choiceCategorie" :key="item.id" :value="item.id">{{ item.nom }}</option>
                            </select>
                            <div class="invalid-feedback">Veuillez choisir une catégorie de taxe</div>
                          </div>
                        </div>
                        <div class="row g-3 text-start mt-2 ">
                          <div  class="col-sm-12">
                            <label for="firstName" class="form-label">Activité</label>
                            <select @change="switchActivite"  v-model="taxe.natureActivite" class=" form-select" required>
                              <option :value="activite.id" v-for="activite in choiceActivite" :key="activite"> {{ activite.nom }}</option>
                            </select>

                            <div class="invalid-feedback">Veuillez choisir une activité</div>
                          </div>

                          <div class="col-sm-12">
                            <label for="firstName" class="form-label">Equipement</label>
                            <select @change="switchEquipement" v-model="taxe.typeEquipement"  class=" form-select" >
                              <option v-for="item in choiceEquipement" :key="item.id" :value="item.id" >{{ item.nom }}</option>
                            </select>
                            <div class="invalid-feedback">Veuillez choisir un équipement</div>
                          </div>
                        </div>
                        <div  class="col-sm-12 text-start mt-3">
                          <label for="firstName" class="form-label">Frequence</label>
                          <select v-model="taxe.frequence"  class="form-select form-control" required>
                            <option v-for="item in listFrequences" :key="item.id" :value="item.id" >{{ item.nom }}</option>
                          </select>


                          <div class="invalid-feedback">Veuillez choisir une fréquence</div>
                        </div>
                        <div class="row mt-3">
                          <div class="form-check form-switch form-switch-lg" dir="ltr">
                            <input v-model="isPrecision" type="checkbox" class="form-check-input" id="customSwitchsizelg" checked="">
                            <label class="form-check-label" for="customSwitchsizelg">Cette taxe applique t-elle des précisions ?</label>
                          </div>
                        </div>
                        <div v-if="isPrecision === false" class="row mt-3">
                          <table class="table table-bordered">
                            <thead>
                            <th>Annee</th>
                            <th>Tarif</th>
                            <th></th>
                            </thead>
                            <tr v-for="(item, index) in fieldsMontant" :key="item">
                              <td><input v-model="item.annee" style="background: #fff; width: 100%" type="text" class="form-control-sm"/> </td>
                              <td>
                                <input type="text" v-model="item.tarif" class="form-control">
                              </td>
                              <td>
                                <a style="background: #0f5132" @click="addFieldsMontant" class="btn btn-icon btn-success btn-sm"><i class="ri-add-fill"/> </a>
                                <a style="background: #950B02" v-if="index > 0" @click="removeFieldMontant(index)" class="btn btn-danger btn-icon waves-effect waves-light btn-sm"><i class="ri-delete-bin-5-line"/> </a>

                              </td>
                            </tr>
                          </table>


                        </div>

                        <div v-if="isPrecision === true" class="row mt-3 border-top">

                          <table class="table table-striped">
                            <tr class="bg-success">
                              <td colspan="3"><span class="fs-16 fw-bold text-white">Precision</span></td>
                            </tr>
                            <tr>
                              <td>Annee</td>
                              <td>Libelle</td>
                              <td>Tarif</td>
                              <td></td>
                            </tr>
                            <tr v-for="(field, index) in fields" :key="index">
                              <td>
                                <input v-model="field.annee" class="form-control" />
                              </td>
                              <td>
                                <input v-model="field.libelle" class="form-control" />
                              </td>
                              <td>
                                <input v-model="field.tarif" class="form-control" >
                              </td>
                              <td>
                                <a style="background: #0f5132" @click="addFields" class="btn btn-icon btn-success btn-sm"><i class="ri-add-fill"/> </a>
                                <a style="background: #950B02" v-if="index > 0" @click="removeField(index)" class="btn btn-danger btn-icon waves-effect waves-light btn-sm"><i class="ri-delete-bin-5-line"/> </a>
                              </td>
                            </tr>
                          </table>

                        </div>
                      </div>

                      <div class="d-flex align-items-start gap-3 mt-4">
                        <button @click="goToNextTab('Applications')" type="button" class="btn btn-success btn-label right ms-auto nexttab nexttab" data-tab="v-pills-bill-info" data-nexttab="v-pills-bill-address-tab"><i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Suivant</button>
                      </div>
                    </div>
                    <!-- end tab pane -->
                    <div class="tab-pane fade show " id="v-pills-bill-address" role="tabpanel"  aria-labelledby="v-pills-bill-address-tab">

                      <div>
                        <div class="list-group col nested-list nested-sortable text-start">
                          <div :key="item" v-for="item in applications">
                            <div  class="list-group-item nested-1" draggable="false">
                              <div class="form-check form-switch form-switch-success">
                                <input v-model="item.selected" @click="handleSelectAll(item)"  type="checkbox" class="form-check-input"  role="switch" id="flexSwitchCheckChecked" >
                                {{ item.nomRamification }} [{{item.type}}]
                              </div>
                              <table v-if="isPrecision === true"  class="table table-bordered">
                                <thead>
                                <th>Annee</th>
                                <th>Libelle</th>
                                <th>Tarif</th>
                                <th></th>
                                </thead>
                                <tr v-for="precision in item.precisions" :key="precision">
                                  <td><input v-model="precision.annee" style="background: #fff; width: 100%" type="text" class="form-control-sm"/> </td>
                                  <td><input v-model="precision.libelle" style="background: #fff; width: 100%" type="text" class="form-control-sm"/> </td>
                                  <td><input v-model="precision.tarif" style="background: #fff; width: 100%" type="text" class="form-control-sm"/> </td>
                                  <td></td>
                                </tr>
                              </table>
                              <div v-if="isPrecision === false" class="row mt-3">
                                <table class="table table-bordered">
                                  <thead>
                                  <th>Annee</th>
                                  <th>Tarif</th>
                                  </thead>
                                  <tr v-for="(tarification, indexTarification) in item.fieldsMontant" :key="indexTarification">
                                    <td><input v-model="tarification.annee" style="background: #fff; width: 100%" type="text" class="form-control-sm"/> </td>
                                    <td>
                                      <input type="text" v-model="tarification.tarif" style="background: #fff; width: 100%"  class="form-control">
                                    </td>

                                  </tr>
                                </table>


                              </div>


                            </div>

                          </div>
                        </div>


                      </div>
                      <div class="d-flex align-items-start gap-3 mt-4">
                        <button type="button" class="btn btn-light btn-label previestab" data-previous="v-pills-bill-info-tab"><i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Revenir aux infos</button>

                        <button  @click="goToNextTab('Recap')" type="button" class="btn btn-success btn-label right ms-auto" data-nexttab="v-pills-payment-tab"><i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Suivant</button>
                      </div>
                    </div>
                    <!-- end tab pane -->
                    <div class="tab-pane fade text-start" id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">

                      <div class="row" v-if="selectedCollectivite != null">
                        <div class="col-xl-12">
                          <div class="card overflow-hidden">
                            <div class="card-body bg-marketplace d-flex">
                              <div class="flex-grow-1">

                              </div>
                              <img src="../../assets/images/logobko.png" style="width: 95px;height:150px"  class="img-fluid">
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <table class="table table-card">
                          <thead>
                          <th>Sous-entités</th>
                          <th>Montant/Précision</th>
                          </thead>
                          <tbody>
                          <tr v-for="application in selectedApplications" :key="application.id" >
                            <td>{{ application.nomRamification }}</td>
                            <td>
                              <span v-if="isPrecision === false">
                                <ul >
                                <li :key="field" v-for="field in application.fieldsMontant">
                                  {{field.annee}} - {{field.tarif}}
                                </li>
                              </ul>
                              </span>
                              <ul v-if="isPrecision === true">
                                <li :key="precision" v-for="precision in application.precisions">
                                  {{precision.annee}} {{precision.libelle}} - {{precision.tarif}}
                                </li>
                              </ul>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="d-flex align-items-start gap-3 mt-4">
                        <button type="button" class="btn btn-light btn-label previestab" data-previous="v-pills-bill-address-tab"><i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Retourner aux applications</button>
                        <button  @click="goToNextTab('Final')" type="button" class="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="v-pills-finish-tab"><i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Valider & créer</button>
                      </div>
                    </div>
                    <!-- end tab pane -->
                    <div class="tab-pane fade" id="v-pills-finish" role="tabpanel" aria-labelledby="v-pills-finish-tab">
                      <div class="text-center pt-4 pb-2">

                        <div class="mb-4">
                          <lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop" colors="primary:#0ab39c,secondary:#405189" style="width:120px;height:120px"></lord-icon>
                        </div>
                        <h5>Félicitation !</h5>
                        <p class="text-muted">Votre taxe a bien été créér et opérationnel</p>
                      </div>
                    </div>
                    <!-- end tab pane -->
                  </div>
                  <!-- end tab content -->
                </div>
              </div>
              <!-- end col -->

            </div>
            <!-- end row -->
          </form>



        </div>
      </div>

    </div>
  </div>

</template>
<script>
import EntiteRepo from "@/repository/EntiteRepository";
import CategorieRepo from "@/repository/CategorieTaxeRepository";
import RamificationRepo from "@/repository/RamificationRepository";
import NatureActiviteRepo from "@/repository/NatureActiviteRepository";
import TypeEquipementRepository from "@/repository/TypeEquipementRepository";
import FrequenceRepository from "@/repository/FrequenceRepository";
import TaxeRepository from "@/repository/TaxeRepository";
import ApplicationRepository from "@/repository/ApplicationRepository";
import PrecisionRepository from "@/repository/PrecisionRepository";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Taxe from "@/entity/Taxe";
import $ from "jquery";

export default {
  name: 'CreateTaxe',
  components: {
    Loading
  },
  computed: {


    selectedCollectivite(){
      if (this.taxe.entite != null){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //console.log(this.listTypeEquipements)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.choiceEquipement = (this.listTypeEquipements != null) ? this.listTypeEquipements.filter(item => item.entite.id === this.taxe.entite) : [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.choiceCategorie = (this.listCategorieTaxes) ? this.listCategorieTaxes.filter(item => item.entite.id === this.taxe.entite) : [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.choiceActivite = this.listNatureActivites.filter(item => item.entite.id === this.taxe.entite);

        return this.listCollectivite.find(item => item.id === this.taxe.entite);
      }
      else return null;
    },
    selectedApplications(){
      return this.applications.filter(item => item.selected === true)
    }

  },
  data(){
    return {
      isLoading: false,
      fullPage: true,
      annee: null,
      listAnnee: [2022, 2023, 2024, 2025, 2026],
      montant: null,
      isPrecision: false,
      choiceActivite: null,
      choiceEquipement: null,
      choiceCategorie: null,
      entiteRepo: new EntiteRepo(),
      precisionRepo: new PrecisionRepository(),
      taxeRepo: new TaxeRepository(),
      appRepo: new ApplicationRepository(),
      categorieRepo: new CategorieRepo(),
      natureRepo: new NatureActiviteRepo(),
      equipementRepo: new TypeEquipementRepository(),
      frequenceRepo: new FrequenceRepository(),
      listNatureActivites: null,
      listFrequences: null,
      listTypeEquipements: null,
      applications: [],
      listCollectivite: null,
      listRamifications: null,
      ramificationRepo: new RamificationRepo(),
      taxe: new Taxe(),
      listCategorieTaxes: null,
      fields: [
        { libelle: '', tarif: '', annee: '' }
      ],
      fieldsMontant: [
        { tarif: '', annee: '' }
      ],

    }
  },
  created() {
    this.isLoading = true;
    this.entiteRepo.search([{ colonne: '', operator: '', value: '' }], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.listCollectivite = response.data;
          //   console.log(this.listCollectivite);
        })

    this.categorieRepo.search([{ colonne: 'c.enabled', operator: 'eq', value: 1 }], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listCategorieTaxes = response.data;
          // console.log(this.listCollectivite);
        })
    this.natureRepo.search([{ colonne: 'na.enabled', operator: 'eq', value: 1 }], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listNatureActivites = response.data;
          // console.log(this.listCollectivite);
        })
    this.equipementRepo.search([{ colonne: 'teq.enabled', operator: 'eq', value: 1 }], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listTypeEquipements = response.data;
          // console.log(this.listCollectivite);
        })

    this.frequenceRepo.search([{ colonne: 'fr.enabled', operator: 'eq', value: 1 }], 'fr.nom', 'asc', 30, 1)
        .then(response => {
          this.listFrequences = response.data;
          // console.log(this.listCollectivite);
        })

    this.taxeRepo.find(this.$route.params.id)
        .then(response => {
          this.taxe = response;
          this.taxe.entite = response.entite.id
          this.taxe.appartenance = (response.appartenance != null)? response.appartenance.id : null;
          this.taxe.typeEquipement = (this.taxe.typeEquipement != null) ? this.taxe.typeEquipement.id : null;
          this.taxe.natureActivite = (this.taxe.natureActivite != null) ? this.taxe.natureActivite.id : null;
          this.taxe.categorie = (this.taxe.categorie != null) ? this.taxe.categorie.id : null;
          this.taxe.frequence = (this.taxe.frequence != null) ? this.taxe.frequence.id : null;
          if(this.taxe.applications.length > 0){
            if (this.taxe.applications[0].precisione.length >0){
              this.isPrecision = (this.taxe.applications[0].precisione.length > 0);
              this.annee = this.taxe.applications.filter(item => this.taxe.applications[0].ramification.id === item.ramification.id).map(app => app.annee );
            }
          }

          if (this.taxe.applications.length >0){
            this.fields = []

            this.fieldsMontant = (!this.isPrecision) ? this.taxe.applications.filter(item => this.taxe.applications[0].ramification.id === item.ramification.id).map(app => ({ annee: app.annee,tarif: app.tarif}) ) : [];
            for (const application of this.taxe.applications.filter(item => this.taxe.applications[0].ramification.id === item.ramification.id)){
              if(application.precisione.length > 0){
                for(const precis of application.precisione){
                  this.fields.push({libelle: precis.libelle, tarif: precis.tarif, annee: application.annee})
                }
              }
            }
          }
          //console.log(this.fieldsMontant)
          this.ramificationRepo.search([{ colonne: 'ent.id', operator: 'eq', value: this.taxe.entite }], 'ram.nom', 'asc', 500, 1)
              .then(response => {
                this.listRamifications = response.data;
                //   console.log(this.listRamifications);

                this.listRamifications.forEach(item => {
                  // console.log(item)
                  this.applications.push({annee: '','nomRamification': item.nom, 'ramification': item.id, precisions : this.fields,  'parent': null, 'type': item.type.nom, selected: true, tarif: this.montant, fieldsMontant : this.fieldsMontant})

                })
                //console.log(this.applications)
                this.isLoading=false;

              })
        });
  },
  methods:{
    setAnnee(){
      this.fieldsMontant = [];
      this.fields = [];
      this.annee.forEach(annee =>{
        this.fieldsMontant.push({annee: annee, tarif: ''});
        this.fields.push({annee: annee, libelle: '', tarif: ''});
        //  console.log(this.applications);
      })
      this.applications.forEach(item => {
        item.fieldsMontant = this.fieldsMontant;
        item.precisions = this.fields;
      })

    },
    switchEquipement(){
        this.taxe.natureActivite = null;

    },
    switchActivite(){
        this.taxe.typeEquipement = null;

    },
    async goToNextTab(step){
      let step1Btn = this.$refs.step2;
      let step2Btn = this.$refs.step3;
      //let step3Btn = this.$refs.step4;
      const formulaire = this.$refs.formulaire;
      formulaire.classList.add('was-validated');

      if (step === 'Applications'){
        // console.log(this.taxe.entite)
        if (this.taxe.entite !== null && this.taxe.categorie !== null && this.taxe.nom !== null && this.taxe.frequence !== null && (this.taxe.typeEquipement !==null || this.taxe.natureActivite !==null)){
          formulaire.classList.remove('was-validated');
          //  console.log(elem.click())
          step1Btn.click();
        }

        // form.classList.add('was-validated');
        //

        // elem.click();

      }
      if (step === 'Recap'){
        formulaire.classList.add('was-validated');

        const sel = this.applications.filter(item => item.selected === true);
        if (sel.length >0){
          // console.log(this.taxe.entite)
          formulaire.classList.remove('was-validated');
          //  console.log(elem.click())
          step2Btn.click();
        }


        // form.classList.add('was-validated');
        //

        // elem.click();

      }
      if (step === 'Final'){
        this.isLoading = true;

        let applicationToBeCreated = [];
        let applicationIds = [];


        this.taxe.applications = [];
        this.taxe.applications = this.applications.filter(item => item.selected === true);

        if(this.isPrecision) {
          for (const an of this.annee) {
            let precisionIds = [];
            let precisionToBeCreated = this.fields.filter(preci => preci.annee.toString() === an.toString());

            for(const preci of precisionToBeCreated){
              let precisionResponse = await this.precisionRepo.create(preci);
              precisionIds.push(precisionResponse.id);
            }
            console.log(precisionIds);

            let applicationToBeCreated = this.applications.filter(app => app.selected === true)
                .map(application => {
                  return {
                    ramification: application.ramification,
                    annee: an.toString(),
                    precisione:  precisionIds,
                    tarif: null
                  };
                });

            console.log(applicationToBeCreated)

            for (const tobecreated of applicationToBeCreated ){
              await this.appRepo.create(tobecreated)
                  .then(response => {
                    applicationIds.push(response.id);
                  });
            }
          }

          this.taxe.applications = applicationIds;

          await this.taxeRepo.edit(this.taxe, this.$route.params.id);
          this.$router.push({'name': 'listTaxes'})
        }
        if(!this.isPrecision) {
          applicationIds = [];
          console.log(this.fieldsMontant)

          for (let an of this.annee) {
            applicationToBeCreated = this.applications.filter(app => app.selected === true)
                .map(application => {
                  return {
                    ramification: application.ramification,
                    annee: an.toString(),
                    tarif: this.fieldsMontant.find(item => item.annee.toString() === an.toString()).tarif
                  };
                });

            for (const application of applicationToBeCreated ){
              await this.appRepo.create(application)
                  .then(response => {
                    applicationIds.push(response.id)
                  })
            }
          //  console.log(applicationIds)

          }

          this.taxe.applications = applicationIds;
          await this.taxeRepo.edit(this.taxe, this.$route.params.id)
              .then(response => {
                if(response.id){
                  this.isLoading = false;
                  this.$router.push({'name': 'listTaxes'})

                }
              });

          //   console.log(precisionIds)
        }


      }

      //Pervies tab
      formulaire.querySelectorAll(".previestab").forEach(function (prevButton) {

        prevButton.addEventListener("click", function () {
          // console.log("prevButton", prevButton);
          var prevTab = prevButton.getAttribute('data-previous');
          var totalDone = prevButton.closest("form").querySelectorAll(".custom-nav .done").length;
          for (var i = totalDone - 1; i < totalDone; i++) {
            (prevButton.closest("form").querySelectorAll(".custom-nav .done")[i]) ? prevButton.closest("form")
                .querySelectorAll(".custom-nav .done")[i].classList.remove('done') : '';
          }
          document.getElementById(prevTab).click();
        });
      });



    },
    setMontant(){
      this.applications.forEach(item => {
        item.tarif = this.montant;
      });
    },

    handleSelectAll(application) {
      this.filteredItems(application.ramification).forEach(item => {
        if (item.selected === false){
          item.selected = true;
        }
        else {
          item.selected = false;
        }

      })
      //   console.log(application);

      //console.log(this.selectedPermissions);
      //  console.log(nav.id);
    },

    filteredItems(parentId) {
      //console.log(this.applications.filter(item => item.parent === parentId));
      return this.applications.filter(item => item.parent === parentId || item.id === parentId)
    },
    addFields() {
      this.fields.push({ libelle: '', tarif: '', annee: '' });
    },
    addFieldsMontant() {
      this.fieldsMontant.push({tarif: '', annee: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    removeFieldMontant(index) {
      this.fieldsMontant.splice(index, 1);
    },
    initialisation(){
      this.applications = [];
      this.ramificationRepo.search([{ colonne: 'ent.id', operator: 'eq', value: this.taxe.entite }], 'ram.nom', 'asc', 500, 1)
          .then(response => {
            this.listRamifications = response.data;
            //   console.log(this.listRamifications);

            this.listRamifications.forEach(item => {
              // console.log(item)
              this.applications.push({annee: '','nomRamification': item.nom, 'ramification': item.id, precisions : this.fields, 'parent': null, 'type': item.type.nom, selected: true, tarif: this.montant, fieldsMontant : this.fieldsMontant})

            })
            //console.log(this.applications)
          })
          .catch(error => {
            console.log(error);
          });
    }

  },
  mounted() {
    $('.js-example-basic-multiple').select2();

  }
}
</script>