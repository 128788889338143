import API from "@/helpers/Api";

export default class Entite {

    id;
    nom;
    nomCourt;
    code;
    commission;
    type ;
    telephone;
    adresse;
    description;
    statutJuridique;
    ville;
    enabled = false;
    api = new API();

    constructor() {
        this.id = null;
        this.description = null;
        this.nom = null;
        this.enabled = false;
        this.ville = null;
        this.type = null;
        this.statutJuridique = null;

    }


  
     

}