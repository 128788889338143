import ListeEntite from "@/components/administration/entite/ListeEntite";
import CreateEntite from "@/components/administration/entite/CreateEntite";
import EditEntite from "@/components/administration/entite/EditEntite";
import CreateEntiteUser from "@/components/administration/entite/CreateEntiteUser";


export default [

    {
        path: '/administration/collectivite',
        name: 'listeEntite',
        component: ListeEntite
    },
    {
        path: '/administration/collectivite/create',
        name: 'createCollectivite',
        component: CreateEntite
    },
    {
        path: '/administration/collectivite/:id',
        name: 'editCollectivite',
        component: EditEntite
    },
    {
        path: '/administration/collectivite/:id/user-create',
        name: 'createCollectiviteUser',
        component: CreateEntiteUser
    }
]