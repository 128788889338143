import API from '@/helpers/Api';
import User from '@/entity/User'
import store from "@/store";

export default class MoyenPaiementRepository extends API {
      user = new User();

      orderByOptions= [
        { text: 'id', value: 'id', name: 'mp.id' },
        { text: 'nom', value: 'nom', name: 'mp.nom' },
        { text: 'commission', value: 'commission', name: 'mp.commission' },
        { text: 'description', value: 'description', name: 'mp.description' },
        { text: 'createdAt', value: 'createdAt', name: 'mp.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'mp.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'mp.enabled' },
    ];

    create(data){
        const moyenPaiement = this.post('/v2/moyen_paiements', data)
        return moyenPaiement;
    }
    edit(data, id){
        const moyen_paiements = this.patch('/v2/moyen_paiements/'+id, data)
        return moyen_paiements;
    }
    find(id){
        const moyen_paiements = this.findOne('/v2/moyen_paiements/'+id)
        return moyen_paiements;
    }
    supprimer(id){
        const moyen_paiements = this.delete('/v2/moyen_paiements/'+id)
        return moyen_paiements;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage) {

        this.user = store.state.user
        //console.log(this.user.ramifications)
        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const moyen_paiements = this.listing('/v2/moyen_paiements', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return moyen_paiements;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const moyen_paiements = this.listing('/v2/moyen_paiements?orderBy=mp.nom&sortOrder=asc&itemperpage=30&page=1&filter[mp.id][type]=in&filter[mp.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(ramifications);
                return moyen_paiements;
            }
            if (this.user.moyen_paiements.length >  0){
                this.user.moyen_paiements.forEach(item => {
                    ids.push(item.id)
                })
                const moyen_paiements = this.listing('/v2/moyen_paiements?filter[p.id][type]=in&filter[p.id][x]='+ids +'&filter[mp.id][type]=in&filter[mp.id][x]='+ids + '&filter[mp.id][connector]=or'+'&filter[pp.id][type]=in&filter[pp.id][x]='+ids + '&filter[pp.id][connector]=or'+'&filter[ppp.id][type]=in&filter[ppp.id][x]='+ids + '&filter[ppp.id][connector]=or'+'&filter[pppp.id][type]=in&filter[pppp.id][x]='+ids + '&filter[pppp.id][connector]=or' , fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(ramifications);
                return moyen_paiements;
            }
        }

    }

}