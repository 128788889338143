import API from "@/helpers/Api";
import User from "@/entity/User";

export default class VilleRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'v.id' },
        { text: 'nom', value: 'nom', name: 'v.nom' },
        { text: 'description', value: 'description', name: 'v.description' },
        { text: 'createdAt', value: 'createdAt', name: 'v.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'v.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'v.enabled' },
    ];

    create(data){
        const villes = this.post('/v2/villes', data)
        return villes;
    }
    edit(data, id){
        const villes = this.patch('/v2/villes/'+id, data)
        return villes;
    }
    find(id){
        const villes = this.findOne('/v2/villes/'+id)
        return villes;
    }
    supprimer(id){
        const villes = this.delete('/v2/villes/'+id)
        return villes;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        const villes = this.listing('/v2/villes', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(ramifications);
        return villes;
    }


}