import ListeTypeEquipement from "@/components/type_equipement/ListeTypeEquipement";
import CreateTypeEquipement from "@/components/type_equipement/CreateTypeEquipement";
import EditTypeEquipement from "@/components/type_equipement/EditTypeEquipement";


export default [

    {
        path: '/type-equipement',
        name: 'listeTypeEquipement',
        component: ListeTypeEquipement
    },
    {
        path: '/type-equipement/create',
        name: 'createTypeEquipement',
        component: CreateTypeEquipement
    },
    {
        path: '/type-equipement/:id',
        name: 'editTypeEquipement',
        component: EditTypeEquipement
    }
]