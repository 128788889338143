import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class ApplicationRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'c.id' },
        { text: 'nom', value: 'nom', name: 'c.nom' },
        { text: 'description', value: 'description', name: 'c.description' },
        { text: 'createdAt', value: 'createdAt', name: 'c.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'c.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'c.enabled' },
    ];

    async create(data){
        const categorie_taxes = await this.post('/v2/applications', data)
        return categorie_taxes;
    }
    edit(data, id){
        const categorie_taxes = this.patch('/v2/applications/'+id, data)
        return categorie_taxes;
    }
    find(id){
        const categorie_taxes = this.findOne('/v2/applications/'+id)
        return categorie_taxes;
    }
    supprimer(id){
        const categorie_taxes = this.delete('/v2/applications/'+id)
        return categorie_taxes;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const applications = this.listing('/v2/applications', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return applications;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const applications = this.listing('/v2/applications?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(applications);
                return applications;
            }
        }
    }


}