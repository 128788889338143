import CreateTaxe from "@/components/taxes/CreateTaxe";
import ListTaxes from "@/components/taxes/ListTaxes";
import EditTaxe from "@/components/taxes/EditTaxe";


export default [

    {
        path: '/taxes',
        name: 'listTaxes',
        component: ListTaxes
    },
    {
        path: '/taxes/create',
        name: 'createTaxes',
        component: CreateTaxe
    },
    {
        path: '/taxes/:id',
        name: 'editTaxes',
        component: EditTaxe
    }
]