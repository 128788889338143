import API from "@/helpers/Api";

export default class CategorieActivite {

    id;
    nom;
    description;
    entite;
    createdAt;
    updatedAt;
    enabled = false;
    api = new API();

    constructor() {
        this.id = null;
        this.description = null;
        this.entite = null;
        this.nom = null;
        this.enabled = true;
    }


  
     

}