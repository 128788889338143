import ListeContribuables from "@/components/impressions/ListeContribuables";
import ListeTransactions from "@/components/impressions/ListeTransactions";
import situationRecouvrement from "@/components/impressions/situationRecouvrement";
import situationImpaye from "@/components/impressions/situationImpaye";
import Releve from "@/components/impressions/Releve";


export default [

    {
        path: '/impressions/liste-des-contribuables',
        name: 'listeImpressions',
        component: ListeContribuables
    },
    {
        path: '/impressions/liste-des-transactions',
        name: 'impressionTransactions',
        component: ListeTransactions
    },
    {
        path: '/impressions/situation-des-recouvrements',
        name: 'situationRecouvrement',
        component: situationRecouvrement
    },
    {
        path: '/impressions/situation-des-impayes',
        name: 'situationImpayes',
        component: situationImpaye
    },
    {
        path: '/impressions/releve-des-recouvrements',
        name: 'releveRecouvrement',
        component: Releve
    }

]