import API from "@/helpers/Api";

export default class NavigationRepository extends API {


    orderByOptions= [
        { text: 'id', value: 'id', name: 'nav.id' },
        { text: 'nom', value: 'nom', name: 'nav.nom' },
        { text: 'icon', value: 'icon', name: 'nav.icon' },
        { text: 'url', value: 'url', name: 'nav.url' },
        { text: 'permissions', value: 'permissions', name: 'nav.permissions' },
        { text: 'module', value: 'module', name: 'nav.module' },
    ];

    create(data){
        const navigations = this.post('/v2/navigations', data)
        return navigations;
    }
    edit(data, id){
        const navigations = this.patch('/v2/navigations/'+id, data)
        return navigations;
    }
    find(id){
        const navigations = this.findOne('/v2/navigations/'+id)
        return navigations;
    }
    supprimer(id){
        const navigations = this.delete('/v2/navigations/'+id)
        return navigations;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {

        const navigations = this.listing('/v2/navigations', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(navigations);
        return navigations;
    }


}