import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class CategorieTaxeRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'na.id' },
        { text: 'nom', value: 'nom', name: 'na.nom' },
        { text: 'description', value: 'description', name: 'na.description' },
        { text: 'createdAt', value: 'createdAt', name: 'na.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'na.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'na.enabled' },
    ];

    create(data){
        const nature_activites = this.post('/v2/nature_activites', data)
        return nature_activites;
    }
    edit(data, id){
        const nature_activites = this.patch('/v2/nature_activites/'+id, data)
        return nature_activites;
    }
    find(id){
        const nature_activites = this.findOne('/v2/nature_activites/'+id)
        return nature_activites;
    }
    supprimer(id){
        const nature_activites = this.delete('/v2/nature_activites/'+id)
        return nature_activites;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const nature_activites = this.listing('/v2/nature_activites', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return nature_activites;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const nature_activites = this.listing('/v2/nature_activites?orderBy=na.nom&sortOrder=asc&itemperpage=30&page=1&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(nature_activites);
                return nature_activites;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const nature_activites = this.listing('/v2/nature_activites?orderBy=na.nom&sortOrder=asc&itemperpage=30&page=1&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(nature_activites);
                return nature_activites;
            }
        }
    }
    getReleve(activite, entite, ramification, from, to){
        const activites = this.post('/v2/contribuables/releve-impaye?generate=true&entite='+entite+'&ramification='+ramification+'&activite='+activite+'&from='+from+'&to='+to)
        return activites;
    }


}