import API from "@/helpers/Api";

export default class Group {

    id;
    nom;
    description;
    createdAt;
    updatedAt;
    enabled = false;
    api = new API();

    constructor() {
        this.id = null;
        this.description = null;
        this.nom = null;
        this.enabled = false;
        this.createdAt = null;
        this.updatedAt = null;

    }


  
     

}