import API from "@/helpers/Api";

export default class FrequenceRepository extends API {


    orderByOptions= [
        { text: 'id', value: 'id', name: 'fr.id' },
        { text: 'nom', value: 'nom', name: 'fr.id' },
        { text: 'description', value: 'description', name: 'fr.description' },
        { text: 'enabled', value: 'enabled', name: 'fr.enabled' },
    ];

    create(data){
        const frequences = this.post('/v2/frequences', data)
        return frequences;
    }
    edit(data, id){
        const frequences = this.patch('/v2/frequences/'+id, data)
        return frequences;
    }
    find(id){
        const frequences = this.findOne('/v2/frequences/'+id)
        return frequences;
    }
    supprimer(id){
        const frequences = this.delete('/v2/frequences/'+id)
        return frequences;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {

        const frequences = this.listing('/v2/frequences', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(frequences);
        return frequences;
    }


}