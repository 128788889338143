<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Catégorie de taxe</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
            <li class="breadcrumb-item active">Categorie de taxes</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="create">
        <div class="card">
          <div class="card-body">
            <div class="text-start">
              <label for="basiInput" class="form-label">Nom</label>
              <input v-model="categorieTaxe.nom" type="text" required class="form-control" id="basiInput">
            </div>
            <br>
            <div class="text-start">
              <label for="basiInput" class="form-label">Collectivité</label>
              <select v-model="categorieTaxe.entite" class="form-control form-select" data-choices name="choices-single-default" id="choices-single-default">
                <option v-for="item in listCollectivite" :key="item.id" :value="item.id">{{ item.nom }}</option>
              </select>

            </div>
            <br>

            <div class="text-start">
              <label for="basiInput" class="form-label">Description</label>
              <textarea v-model="categorieTaxe.description" class="form-control"></textarea>
            </div>
            <br>
            <div class="text-start">
              <div class="form-check form-switch">
                <label class="form-check-label text-start" for="flexSwitchCheckChecked">Activé ?</label>
                <input v-model="categorieTaxe.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked="">
              </div>
            </div>
          </div>
          <div class="card-footer text-start">
            <button type="submit" class="btn btn-primary">Enregistrer</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>
<script>
import CategorieTaxe from "@/entity/CategorieTaxe";
import Repository from "@/repository/CategorieTaxeRepository";
import EntiteRepo from "@/repository/EntiteRepository";

export default {
  name: 'CreateCategorieTaxe',
  data(){
    return {
      categorieTaxe : new CategorieTaxe(),
      repository: new Repository(),
      listCollectivite: null,
      entiteRepo: new EntiteRepo(),


      //choices: new Choices()
    }
  },
  methods: {
    create(){
      this.repository.create(this.categorieTaxe)
          .then(response =>{
            this.categorieTaxe = response;
            this.$router.push({name: 'listCategorieTaxes'})
          })
          .catch(error => {
            console.log(error)
          });
    }
  },
  mounted() {



  },
  created() {

    this.entiteRepo.search([{ colonne: '', operator: '', value: '' }], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.listCollectivite = response.data;
          //console.log(this.listCollectivite);
        })
        .catch(error => {
          console.log(error);
        });
  }
}
</script>