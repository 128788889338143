import API from "@/helpers/Api";
import User from "@/entity/User";

export default class TypeRamificationRepository extends API {

    user = new User();

    
    orderByOptions= [
        { text: 'id', value: 'id', name: 'tr.id' },
        { text: 'nom', value: 'nom', name: 'tr.nom' },
        { text: 'description', value: 'description', name: 'tr.description' },
        { text: 'createdAt', value: 'createdAt', name: 'tr.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'tr.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'tr.enabled' },
    ];

    create(data){
        const type_ramifications = this.post('/v2/type_ramifications', data)
        return type_ramifications;
    }
    edit(data, id){
        const type_ramifications = this.patch('/v2/type_ramifications/'+id, data)
        return type_ramifications;
    }
    find(id){
        const type_ramifications = this.findOne('/v2/type_ramifications/'+id)
        return type_ramifications;
    }
    supprimer(id){
        const type_ramifications = this.delete('/v2/type_ramifications/'+id)
        return type_ramifications;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        const type_ramifications = this.listing('/v2/type_ramifications', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(ramifications);
        return type_ramifications;
    }


}