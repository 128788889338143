import API from "@/helpers/Api";
import store from '@/store'

export default class AgentRepository extends API {


    orderByOptions= [
            { text: 'id', value: 'id', name: 'user.id' },
        { text: 'nom', value: 'nom', name: 'user.nom' },
        { text: 'prenom', value: 'prenom', name: 'user.prenom' },
        { text: 'email', value: 'email', name: 'user.email' },
        { text: 'Nom d\'utilisateur ', value: 'username', name: 'user.username' },
        { text: 'createdAt', value: 'createdAt', name: 'user.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'user.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'user.enabled' },
        /*{ text: 'Affectation', value: 'ram.id', name: 'ram.id' },
        { text: 'Collectivite', value: 'organisation', name: 'ent.id' },*/
    ];
    statutOptions = [true, false]

    create(data){
        const users = this.post('/v2/users', data)
        return users;
    }
    edit(data, id){
        const users = this.patch('/v2/users/'+id, data)
        return users;
    }

    check(email, password){
        const users = this.post('/v2/users/check', {"email" : email, "password": password})
        return users;
    }
    find(id){

        const users = this.findOne('/v2/users/'+id)
        return users;
    }
    supprimer(id){
        const users = this.delete('/v2/users/'+id)
        return users;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user
        //console.log(this.user.ramifications)
        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const users = this.listing('/v2/users', fields, orderBy, sortOrder, itemPerPage, currentPage)
            return users;

        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const users = this.listing('/v2/users?filter[tu.id][type]=eq&filter[tu.id][x]=2&filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(ramifications);
                return users;
            }
            if (this.user.ramifications.length >  0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.id)
                })
                const users = this.listing('/v2/users?filter[tu.id][type]=eq&filter[tu.id][x]=2&filter[ram.id][type]=in&filter[ram.id][x]='+ids   , fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(ramifications);
                return users;
            }
        }
        //console.log(users);
    }


}