import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class EnrollementRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'contrib.id' },
        { text: 'Nom', value: 'nom', name: 'contrib.nom' },
        { text: 'Collectivite', value: 'collectivite', name: 'ent.nom' },
        { text: 'Secteur', value: 'nom', name: 'ram.nom' },
        { text: 'Raison sociale', value: 'raisonSociale', name: 'contrib.raisonSociale' },
        { text: 'Nature activite', value: 'natureActivite', name: 'na.nom' },
        { text: 'Numero Klispay', value: 'numKlis', name: 'contrib.numKlis' },
        { text: 'Date d\'enrollement', value: 'createdAt', name: 'enr.createdAt' },
        { text: 'enabled', value: 'enabled', name: 'contrib.enabled' },
    ];

    create(data){
        const enrollements = this.post('/v2/enrollements', data)
        return enrollements;
    }
    edit(data, id){
        const enrollements = this.patch('/v2/enrollements/'+id, data)
        return enrollements;
    }
    find(id){
        const enrollements = this.findOne('/v2/enrollements/'+id)
        return enrollements;
    }
    supprimer(id){
        const enrollements = this.delete('/v2/enrollements/'+id)
        return enrollements;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const enrollements = this.listingContrib('/v2/enrollements', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return enrollements;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const enrollements = this.listingContrib('/v2/enrollements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(enrollements);
                return enrollements;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const enrollements = this.listingContrib('/v2/enrollements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(enrollements);
                return enrollements;
            }
        }
    }
    searchContrib(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const enrollements = this.listing('/v2/enrollements', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return enrollements;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })

                const enrollements = this.listing('/v2/enrollements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(enrollements);
                return enrollements;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const enrollements = this.listing('/v2/enrollements?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(enrollements);
                return enrollements;
            }
        }
    }


}